import { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import ReactPaginate from "react-paginate";
import Select, { components, DropdownIndicatorProps } from "react-select";
import DatePicker from "react-datepicker";
import { ReactSVG } from "react-svg";
import Moment from "react-moment";
import moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { IPage } from "../../interfaces";

import "react-datepicker/dist/react-datepicker.css";

import styles from "../../static/styles/Concerts.module.scss";

import BreadCrumbs from "../common/components/BreadCrumbs/BreadCrumbs.tsx";

const GET_CONCERTS = gql`
  query GetConcerts(
    $page: Int
    $pageSize: Int
    $genre: String
    $price: Float
    $date: DateTime
    $region: String
    $city: String
    $endDate: DateTime
  ) {
    concerts(
      filters: {
        genres: { title: { contains: $genre } }
        and: [
          { price: { gt: $price } }
          { date: { gte: $date } }
          { date: { lte: $endDate } }
          { country: { name: { eq: $region } } }
          { city: { name: { eq: $city } } }
        ]
      }
      pagination: { page: $page, pageSize: $pageSize }
    ) {
      data {
        id
        attributes {
          title
          imageUrl
          ageRestrictions
          genres {
            data {
              attributes {
                title
              }
            }
          }
          price
          date
          city {
            data {
              attributes {
                name
              }
            }
          }
          location {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
    }
  }
`;

const GET_GENRES = gql`
  query GetGenres {
    genres {
      data {
        id
        attributes {
          title
          value
        }
      }
    }
  }
`;

const mapStateToProps = (state) => {
  return {
    regions: state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    handleChangeRegion: (value) =>
      dispatch({ type: "REGIONS/SET", payload: value }),
  };
};

interface IProps {
  regions: {
    region: string;
    cities: string[];
  };
}

interface IOption {
  value: string | null;
  label: string;
}

export const ConcertsWithoutStore = (props: IProps) => {
  const itemsPerPage = 4;
  const [currentItems, setCurrentItems] = useState(null);
  const [currentGenres, setCurrentGenres] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);
  const [genre, setGenre] = useState("");
  const [price, setPrice] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [city, setCity] = useState("");
  const [timePeriod, setTimePeriod] = useState("default");
  const [cities, setCities] = useState([]);

  const onDateChange = (date) => {
    setStartDate(date);
  };

  const DropdownIndicator = (props: DropdownIndicatorProps) => {
    return (
      <components.DropdownIndicator {...props}>
        <ReactSVG src="/ic-arrow.svg" />
      </components.DropdownIndicator>
    );
  };

  const Control = ({ children, ...props }) => (
    <components.Control {...props}>
      <ReactSVG src="/map-select-ico.svg" /> {children}
    </components.Control>
  );

  Control.propTypes = {
    children: PropTypes.any,
  };

  const { loading, error, data, refetch } = useQuery(GET_CONCERTS, {
    fetchPolicy: "network-only",
    variables: {
      page: 0,
      pageSize: itemsPerPage,
    },
  });
  const { data: dataGenre } = useQuery(GET_GENRES, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    let genresForSelect: IOption[] = dataGenre?.genres.data.map(
      ({ attributes }) => {
        return {
          value: attributes.title,
          label: attributes.title,
        };
      }
    );
    genresForSelect?.unshift({
      value: null,
      label: "Genres",
    });
    setCurrentGenres(genresForSelect);
  }, [dataGenre]);

  useEffect(() => {
    const currentRegion = props?.regions?.cities[props?.regions?.region];
    if (currentRegion) {
      const cities: IOption[] = currentRegion.map((city: string) => ({
        value: city,
        label: city,
      }));
      setCities(cities);
    }
  }, [props?.regions?.region, props?.regions?.cities]);

  useEffect(() => {
    const filters = {
      page: pageOffset,
      genre: genre,
      price: price,
      date: startDate,
      region: props.regions.region,
      city: city,
    };
    if (props.regions.region === "All countries") {
      filters.region = undefined;
    }
    if (props.regions.region === "All countries" || !city) {
      filters.city = undefined;
    }
    if (!genre) {
      filters.genre = undefined;
    }
    if (timePeriod === "upcoming") {
      filters.endDate = moment().add(2, "weeks").format();
    } else {
      filters.endDate = undefined;
    }
    if (!price) {
      filters.price = undefined;
    }
    refetch(filters);
    setCurrentItems(data?.concerts?.data);
    setPageCount(data?.concerts?.meta?.pagination?.pageCount);
  }, [
    data,
    pageOffset,
    itemsPerPage,
    refetch,
    genre,
    price,
    startDate,
    dataGenre?.genres.data,
    props.regions.region,
    city,
    timePeriod,
  ]);

  const handlePageClick = (event) => {
    setPageOffset(event.selected + 1);
  };

  const genreChange = (value: string) => {
    setGenre(value);
  };

  const priceChange = (value: string) => {
    setPrice(Number(value));
  };

  const showRegionSelect: string = props?.regions?.region;

  const timePeriodOptions: IOption[] = [
    {
      value: "default",
      label: "Time period",
    },
    {
      value: "upcoming",
      label: "Nearest",
    },
  ];

  const priceOptions: IOption[] = [
    {
      value: "0",
      label: "Price",
    },
    {
      value: "500",
      label: "500",
    },
    {
      value: "1000",
      label: "1000",
    },
  ];

  const breadCrumbsPages: IPage[] = [
    {
      link: "/",
      title: "Main",
    },
    {
      link: "/concerts",
      title: "Events",
    },
  ];

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      minWidth: state.selectProps.minWidth,
      backgroundColor: "#121C25",
    }),
    control: (provided, state) => ({
      ...provided,
      minWidth: state.selectProps.minWidth,
      backgroundColor: "#121C25",
      borderRadius: "22px",
      fontSize: "14px",
      paddingLeft: "20px",
      caretColor: "#3297C2",
      border: "none",
      marginRight: "20px",
      marginLeft: "0",
      color: "#F2F2F2",
      "&:hover": {
        cursor: "pointer",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "#020C14" : "#121C25",
      "&:hover": {
        backgroundColor: "#3297C2",
        cursor: "pointer",
      },
    }),
    input: (provided) => ({
      ...provided,
      color: "#F2F2F2",
      marginLeft: "0",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#F2F2F2",
      fontSize: "16px",
      textAlign: "center",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    indicatorsContainer: () => ({
      marginRight: "5px",
    }),
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <div>
      <div className={styles.concertsHeader}>
        <h2 className="sub-title">
          Concert poster in {showRegionSelect || ""}
        </h2>
      </div>
      <BreadCrumbs pages={breadCrumbsPages} />
      <div className={styles.filters}>
        <Select
          onChange={(e) => {
            setTimePeriod(e.value);
          }}
          defaultValue={timePeriodOptions[0]}
          styles={customStyles}
          components={{ DropdownIndicator }}
          minWidth="160px"
          aria-label="Default"
          options={timePeriodOptions}
          isSearchable={false}
        ></Select>
        <DatePicker
          className={styles.datePicker}
          selected={startDate}
          onChange={onDateChange}
        />
        <Select
          onChange={(e) => {
            priceChange(e.value);
          }}
          defaultValue={priceOptions[0]}
          components={{ DropdownIndicator }}
          styles={customStyles}
          options={priceOptions}
        ></Select>
        <Select
          onChange={(e) => {
            genreChange(e.value);
          }}
          defaultValue={currentGenres && currentGenres[0]}
          components={{ DropdownIndicator }}
          options={currentGenres}
          styles={customStyles}
        ></Select>
        <div className={styles.geo}>
          {showRegionSelect &&
            showRegionSelect !== "All countries" &&
            props.regions?.cities &&
            props.regions?.cities[props.regions.region] && (
              <Select
                onChange={(e) => {
                  setCity(e.value);
                }}
                options={cities}
                components={{ Control, DropdownIndicator }}
                styles={customStyles}
                placeholder="City"
              ></Select>
            )}
        </div>
      </div>
      <div className={styles.cards}>
        {currentItems?.map(({ id, attributes }) => (
          <div key={id} className={styles.card}>
            <img alt="" className={styles.cardImg} src={attributes.imageUrl} />
            <div className={styles.cardBody}>
              <div className={styles.cardTitle}>{attributes.title}</div>
              <div className={styles.cardText}>
                {attributes.city?.data.attributes.name},{" "}
                {attributes.location?.data.attributes.name}
                <div className={styles.date}>
                  <Moment className={styles.date} format="MMM Do YY">
                    {attributes.date}
                  </Moment>
                </div>
              </div>
              <Link className={styles.link} to={`/concert/${id}`}>
                Read more
              </Link>
            </div>
            <p className={styles.age}>{attributes.ageRestrictions}+</p>
          </div>
        ))}
      </div>
      <ReactPaginate
        className="paginationReact"
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
      />
      <div className={styles.features}>
        <div className={styles.feature}>
          <div className={styles.featPromo}>
            <div className={styles.iconWrapper}>
              <ReactSVG src="/tile-icon-service.svg" className={styles.icon} />
            </div>
            <div className={styles.promoH1}>Order a service</div>
          </div>
          <div className={styles.promoText}>
            <strong>Atom Entertainment Group</strong> lives up to its name one
            hundred percent, offering spectacular and high-quality shows to the
            Belarusian public. The company&apos;s concept is as simple as an
            atom: make a &quot;big bang&quot; in the world of entertainment.
          </div>
          <Link className={styles.promoLink} to="/activity/services">
            Order a service
          </Link>
        </div>
        <div className={styles.feature}>
          <div className={styles.featPromo}>
            <div className={styles.iconWrapper}>
              <ReactSVG src="/tile-icon-music.svg" className={styles.icon} />
            </div>
            <div className={styles.promoH1}>To book an artist</div>
          </div>
          <div className={styles.promoText}>
            The portfolio of the group of companies includes many successful
            corporate holidays, team building and outdoor events, as well as
            mass events with the participation of artists, sports and movie
            stars, open air festivals, PR and BTL promotions.
          </div>
          <Link className={styles.promoLink} to="/artists/">
            To book an artist
          </Link>
        </div>
      </div>
    </div>
  );
};

export const Concerts = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConcertsWithoutStore);
