import classnames from "classnames";

import styles from "./Button.module.scss";

interface IProps {
  text: string;
  disable: boolean;
  onClick: () => {};
}

export const Button = (props: IProps) => {
  return (
    <button
      onClick={props.onClick}
      className={classnames(styles.button, props.disable && styles.disable)}
    >
      {props.text}
    </button>
  );
};

export default Button;
