import styles from "./BreadCrumbs.module.scss";
import { Link } from "react-router-dom";
import { IPage } from "../../../../interfaces";
import { Fragment } from "react";

interface IProps {
  pages: IPage[];
}

export const BreadCrumbs = (props: IProps) => {
  return (
    <div className={styles.wrapper}>
      {props.pages.map((page: IPage, idx: number) => {
        return (
          <Fragment key={idx}>
            <Link to={page.link} className={styles.link}>
              {page.title}
            </Link>
            {idx < props.pages.length - 1 ? "/" : ""}
          </Fragment>
        );
      })}
    </div>
  );
};

export default BreadCrumbs;
