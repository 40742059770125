import { ReactSVG } from "react-svg";

import BreadCrumbs from "../common/components/BreadCrumbs/BreadCrumbs.tsx";
import { CallToActionForm } from "../common/components/CallToActionForm/CallToActionForm.tsx";
import { IPage } from "../../interfaces";

import styles from "../../static/styles/Contacts.module.scss";

export const Contacts = () => {
  const breadCrumbsPages: IPage[] = [
    {
      link: "/",
      title: "Main",
    },
    {
      link: "#",
      title: "Contacts",
    },
  ];

  return (
    <div className={styles.wrapper}>
      <h2 className="sub-title">Contacts</h2>
      <BreadCrumbs pages={breadCrumbsPages} />
      <div className={styles.mainBlock}>
        <div className={styles.adressBlock}>
          <div className={styles.phones}>
            <img className={styles.phonesIco} src="/ic-phone.png" alt="" />
            <div className={styles.phonesText}>
              +375 29 696 59 84<br></br>
              +375 29 696 59 83<br></br>
              +375 29 696 59 41<br></br>
            </div>
          </div>
          <div className={styles.mail}>
            <img className={styles.mailIco} src="/ic-mail.png" alt="" />
            <div>belbilet@gmail.com</div>
          </div>
          <div className={styles.adress}>
            <img className={styles.adressIco} src="/ic-geo.png" alt="" />
            <div>г. Минск, ул. Сурганова, 2, офис 6</div>
          </div>
          <div className={styles.socials}>
            <a href="https://vk.com" className={styles.social}>
              <ReactSVG src="/ic-vk.svg"></ReactSVG>
            </a>
            <a href="https://fb.com" className={styles.social}>
              <ReactSVG src="/ic-facebook.svg"></ReactSVG>
            </a>
            <a href="https://instagram.com" className={styles.social}>
              <ReactSVG src="/ic-instagram.svg"></ReactSVG>
            </a>
          </div>
        </div>
        <div className={styles.map}>
          <img className={styles.mapImage} src="/map.png" alt="" />
        </div>
      </div>
      <CallToActionForm />
    </div>
  );
};
