import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { ITile } from "../../../../interfaces";

import styles from "./TileLink.module.scss";
import "./TileLink.scss";

interface IProps {
  tile: ITile;
}

export const TileLink = (props: IProps) => {
  return (
    <Link
      to={props.tile?.link}
      className={[styles.wrapper, "tile-link"].join(" ")}
    >
      <ReactSVG src={props.tile?.svg} className={styles.icon} />
      {props.tile?.title}
    </Link>
  );
};

export default TileLink;
