import { Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import "./App.scss";
import {
  About,
  Artists,
  Artist,
  Article,
  Concerts,
  Activity,
  Contacts,
  Questions,
  Concert,
  MainPage,
} from "./modules/pages/index.tsx";
import { Nav } from "./modules/common/components/Nav/Nav.tsx";
import { Footer } from "./modules/common/components/Footer/Footer.tsx";
import { SideBar } from "./modules/common/components/Nav/Sidebar.tsx";
import { ContainerRegions } from "./modules/common/components/index.tsx";

import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    console.log(isOpen);
  }, [isOpen]);

  return (
    <div className="App">
      <SideBar
        isOpen={isOpen}
        onStateChange={(s) => setOpen(s.isOpen)}
        pageWrapId={"page-wrap"}
        outerContainerId={"root"}
        handleChange={setOpen}
      />
      <div className="custom-container">
        <Nav handleChange={setOpen} />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/artists" element={<Artists />} />
          <Route path="/artists/:genre" element={<Artists />} />
          <Route
            path="/artist/:id"
            element={<Artist handleChange={setOpen} />}
          />
          <Route path="/concerts" element={<Concerts />} />
          <Route path="/activity/:tag" element={<Activity />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/questions" element={<Questions />} />
          <Route path="/concert/:id" element={<Concert />} />
          <Route path="/about" element={<About />} />
          <Route path="/article/:url" element={<Article />} />
        </Routes>
      </div>
      <ContainerRegions />
      <Footer />
    </div>
  );
};

export default App;
