import { Fragment, useEffect, useState } from "react";
import { useMutation, gql } from "@apollo/client";
import Modal from "react-modal";

import CustomInput from "../Input/Input.tsx";
import Button from "../Button/Button.tsx";
import InputPhone from "../InputPhone/InputPhone.tsx";

import styles from "./CallToActionForm.module.scss";

Modal.setAppElement("#root");

export const CallToActionForm = () => {
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [emptyNameWarning, setEmptyNameWarning] = useState(false);
  const [emptyPhoneWarning, setEmptyPhoneWarning] = useState(false);
  const [justPhoneWarning, setJustPhoneWarning] = useState(false);
  const [wrongNumber, setWrongNumber] = useState(false);
  const [wasFirstTryToSend, setwasFirstTryToSend] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const modalStyles = {
    overlay: {
      display: "flex",
      backgroundColor: "rgba(0, 0, 0, 0.4)",
      backdropFilter: "blur(3px)",
    },
  };

  useEffect(() => {
    if (!wasFirstTryToSend) return;
    if (name.length < 2) {
      setEmptyNameWarning(true);
    } else {
      setEmptyNameWarning(false);
    }
    if (!phone?.length) {
      setEmptyPhoneWarning(true);
    } else if (phone?.length < 12) {
      setWrongNumber(true);
      setJustPhoneWarning(true);
      return;
    } else {
      setWrongNumber(false);
      setJustPhoneWarning(false);
      setEmptyPhoneWarning(false);
    }
  }, [phone, name, wasFirstTryToSend]);

  const CREATE_LEAD = gql`
    mutation createLead($phone: String!, $name: String!) {
      createLead(data: { phone: $phone, name: $name }) {
        data {
          attributes {
            name
            phone
          }
        }
      }
    }
  `;
  const [createLeadMutation, { data, error }] = useMutation(CREATE_LEAD);

  const sendForm = () => {
    setwasFirstTryToSend(true);
    if (name.length < 2 || phone?.length < 12) return;
    createLeadMutation({
      variables: {
        name,
        phone,
      },
      onCompleted: () => setShowModal(true),
    });
  };

  useEffect(() => {
    if (error || data) {
      setShowModal(true);
    }
  }, [error, data]);

  const closeModal = () => {
    document.body.classList.remove("ReactModal__Body--open");
    setShowModal(false);
  };

  return (
    <div className={styles.formWrapper}>
      <div className={styles.formTitle}>
        Do you want to cooperate with us? Our team work 24/7 in all time zones.
      </div>
      <div className={styles.formBody}>
        <div className={styles.input}>
          <CustomInput
            warning={emptyNameWarning}
            onChange={setName}
            value={name}
            type="input"
            placeholder="Your name"
          />
        </div>
        <div className={styles.input}>
          <InputPhone
            warning={emptyPhoneWarning}
            justWarning={justPhoneWarning}
            value={phone}
            onChange={setPhone}
            wrongNumber={wrongNumber}
          />
        </div>
        <div className={styles.buttonWrapper}>
          <Button onClick={sendForm} text="To book an artist" />
        </div>
      </div>
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        style={modalStyles}
        className="callToActionFormModalContent"
      >
        <div className={styles.modalContent}>
          <img
            src="/modal-close.png"
            onClick={closeModal}
            className={styles.modalClose}
            alt=""
          />
          <img
            className={styles.modalImage}
            src={`/${
              error ? "warning-popup-image" : "success-modal-image"
            }.png`}
            alt=""
          />
          <div className={styles.modalTitle}>
            {data && "Ваши данные успешно отправлены!"}
            {error && "Произошла ошибка"}
          </div>
          <div className={styles.modalDescription}>
            {data && "Мы перезвоним вам как можно скорее."}
            {error && (
              <Fragment>
                По техническим причинам мы не смогли отправить ваши данные.
                Пожалуйста, попробуйте еще раз.<br></br>
                {JSON.stringify(error)}
              </Fragment>
            )}
          </div>
          <div className={styles.modalBtn} onClick={closeModal}>
            Хорошо
          </div>
        </div>
      </Modal>
    </div>
  );
};
