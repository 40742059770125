import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import Moment from "react-moment";
import ReactMarkdown from "react-markdown";
import { ReactSVG } from "react-svg";

import BreadCrumbs from "../common/components/BreadCrumbs/BreadCrumbs.tsx";
import {
  TileLink,
  ConcertsSummariesList,
} from "../common/components/index.tsx";
import { IConcertSummary, IPage, ITile } from "../../interfaces";

import styles from "../../static/styles/Concert.module.scss";
import "../../static/styles/Concert.scss";

const GET_CONCERT = gql`
  query GetConcert($id: ID) {
    concert(id: $id) {
      data {
        id
        attributes {
          title
          ageRestrictions
          imageUrl
          description
          text
          city {
            data {
              attributes {
                name
              }
            }
          }
          location {
            data {
              attributes {
                name
              }
            }
          }
          date
          richText
          otherConcerts {
            data {
              attributes {
                date
                city {
                  data {
                    attributes {
                      name
                    }
                  }
                }
                country {
                  data {
                    attributes {
                      name
                    }
                  }
                }
                location {
                  data {
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
          sliderMedia {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
        }
      }
    }
  }
`;

const tiles: ITile[] = [
  {
    title: "To make an order",
    link: "/activity/services/",
    svg: "/tile-icon-service.svg",
  },
  {
    title: "To book an artist",
    link: "/artists/",
    svg: "/tile-icon-music.svg",
  },
  {
    title: "Event poster",
    link: "/",
    svg: "/tile-icon-calendar.svg",
  },
];

export const Concert = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_CONCERT, {
    fetchPolicy: "network-only",
    variables: {
      id: id,
    },
  });

  const swiperRef = useRef(null);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const concertAttrs = data?.concert.data.attributes;
  const otherConcerts: IConcertSummary = concertAttrs.otherConcerts.data;

  const breadCrumbsPages: IPage[] = [
    {
      link: "/",
      title: "Main",
    },
    {
      link: "/concerts",
      title: "Events",
    },
    {
      link: "#",
      title: data?.concert.data.attributes.title,
    },
  ];

  return (
    <div>
      <div className="wrapper head">
        <h2 className="sub-title">{concertAttrs?.title}</h2>
      </div>
      <div className="wrapper head">
        <BreadCrumbs pages={breadCrumbsPages} />
      </div>
      <div className={styles.jumbotron}>
        <img
          className={styles.jumbotronImg}
          src={concertAttrs?.imageUrl}
          alt=""
        />
        <div className={styles.jumbotronAgeRestrictions}>
          {concertAttrs?.ageRestrictions}+
        </div>
        <div className={styles.jumbotronContent}>
          <div className={styles.jumbotronTitle}>{concertAttrs?.title}</div>
          <div className={styles.jumbotronDescription}>
            {concertAttrs?.description}
          </div>
          <div className={styles.jumbotronPlace}>
            <div className={styles.geoIcon}>
              <ReactSVG src="/geoIconConcert.svg" />
            </div>
            {concertAttrs?.city?.data?.attributes.name},{" "}
            {concertAttrs?.location?.data?.attributes.name}
          </div>
          <div className={styles.jumbotronDate}>
            <div className={styles.calendarIcon}>
              <ReactSVG src="/calendarIcon.svg" />
            </div>
            <Moment format="MMM Do YY">{concertAttrs?.date}</Moment>
          </div>
          <div className={styles.jumbotronBtn}>Buy</div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.contentText}>
          <ReactMarkdown>{concertAttrs?.richText}</ReactMarkdown>
        </div>
        <Swiper id="concert-swiper" ref={swiperRef} slidesPerView={1}>
          {concertAttrs?.sliderMedia.data?.map((slide, idx) => (
            <SwiperSlide key={idx}>
              {slide.attributes.url.toUpperCase().endsWith(".MP4") ? (
                <video className={styles.sliderImg} controls>
                  <source src={slide.attributes.url} type="video/mp4" />
                </video>
              ) : (
                <img
                  className={styles.sliderImg}
                  src={slide.attributes?.url}
                  alt={slide.attributes?.alternativeText}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={styles.swiperNav} id="concert-swiper-nav">
          <ReactSVG
            id="previousButton"
            className={styles.prevBtn}
            onClick={() => swiperRef.current.swiper.slidePrev()}
            src="/Swiper-left.svg"
          />
          <ReactSVG
            id="nextButton"
            className={styles.nextBtn}
            onClick={() => swiperRef.current.swiper.slideNext()}
            src="/Swiper-right.svg"
          />
        </div>
      </div>
      <ConcertsSummariesList concerts={otherConcerts}>
        <div className={styles.photoreportBtn}>Photo report</div>
      </ConcertsSummariesList>
      <div className={styles.tiles}>
        {tiles.map((tile: ITile, idx: number) => (
          <TileLink tile={tile} key={idx} />
        ))}
      </div>
    </div>
  );
};
