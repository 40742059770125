import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { useParams } from "react-router-dom";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";

import BreadCrumbs from "../common/components/BreadCrumbs/BreadCrumbs.tsx";
import { CallToActionForm } from "../common/components/CallToActionForm/CallToActionForm.tsx";
import { IPage } from "../../interfaces";

import "../../static/styles/Artists.scss";
import styles from "../../static/styles/Artists.module.scss";

const GET_ARTISTS = gql`
  query GetArtists(
    $page: Int
    $pageSize: Int
    $genre: String
    $searchWord: String
    $russian: Boolean
    $foreign: Boolean
    $corporate: Boolean
    $region: String
  ) {
    artists(
      filters: {
        genres: { title: { contains: $genre } }
        and: [
          { name: { containsi: $searchWord } }
          { russian: { eq: $russian } }
          { foreign: { eq: $foreign } }
          { corporate: { eq: $corporate } }
          { region: { eq: $region } }
        ]
      }
      pagination: { page: $page, pageSize: $pageSize }
    ) {
      data {
        id
        attributes {
          name
          photoUrl
        }
      }
      meta {
        pagination {
          page
          pageSize
          total
          pageCount
        }
      }
    }
  }
`;

const GET_GENRES = gql`
  query GetGenres {
    genres {
      data {
        id
        attributes {
          title
          value
        }
      }
    }
  }
`;

const mapStateToProps = (state) => {
  return {
    regions: state,
  };
};

interface IPropsArtists {
  regions: {
    region: string;
  };
}

export const ArtistsWithoutStore = (props: IPropsArtists) => {
  const itemsPerPage = 8;
  const [pageCount, setPageCount] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);
  const [genre, setGenre] = useState("");
  const [corporate, setCorporate] = useState(null);
  const [currentGenres, setCurrentGenres] = useState(null);
  const [searchWord, setSearchWord] = useState("");
  const [russian, setRussian] = useState(null);
  const { genre: genreUrl } = useParams();
  const [disableGenreByRoute, setDisableGenreByRoute] = useState(false);

  const [getArtists, { loading, error, data, refetch }] = useLazyQuery(
    GET_ARTISTS,
    {
      fetchPolicy: "network-only",
      variables: {
        page: 0,
        pageSize: itemsPerPage,
      },
    }
  );

  const { data: dataGenre } = useQuery(GET_GENRES, {
    fetchPolicy: "network-only",
  });

  const clearFilters = () => {
    setGenre("");
    setCorporate(null);
    setRussian(null);
    setSearchWord("");
  };

  useEffect(() => {
    if (!disableGenreByRoute && genreUrl) {
      setGenre(genreUrl);
      setDisableGenreByRoute(true);
    }
  }, [genreUrl, disableGenreByRoute]);

  useEffect(() => {
    setCurrentGenres(dataGenre?.genres.data);
    const filters = {
      page: pageOffset,
      genre: genre,
      searchWord: searchWord,
      pageSize: itemsPerPage,
      region: props.regions.region,
    };
    if (russian !== null) {
      filters.russian = russian;
    } else {
      filters.russian = undefined;
    }
    if (corporate !== null) {
      filters.corporate = corporate;
    } else {
      filters.corporate = undefined;
    }
    if (props.regions.region === "All countries") {
      filters.region = undefined;
    }
    if (!genre) {
      filters.genre = undefined;
    }
    refetch(filters);
    setPageCount(data?.artists?.meta?.pagination?.pageCount);
  }, [
    refetch,
    getArtists,
    pageOffset,
    itemsPerPage,
    dataGenre,
    genre,
    searchWord,
    russian,
    corporate,
    props.regions.region,
    data?.artists?.meta?.pagination?.pageCount,
  ]);

  const buttonCorporateClasses = classnames(styles.filterBtn, {
    [styles.filterBtnActive]: corporate,
  });

  const buttonRussianClasses = classnames(styles.filterBtn, {
    [styles.filterBtnActive]: russian,
  });

  const buttonForeignClasses = classnames(styles.filterBtn, {
    [styles.filterBtnActive]: !russian && russian !== null,
  });

  const breadCrumbsPages: IPage[] = [
    {
      link: "/",
      title: "Main",
    },
    {
      link: "/artists",
      title: "To book an artist",
    },
  ];

  const handlePageClick = (event) => {
    setPageOffset(event.selected + 1);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;
  return (
    <div>
      <div className="wrapper">
        <div className="artist-navbar">
          <h2 className="sub-title">To book an artist</h2>
          <div className={styles.search}>
            <ReactSVG className={styles.searchIcon} src="/search.svg" />
            <input
              key="good"
              className={styles.searchInput}
              type="text"
              placeholder="Input an artist's name"
              value={searchWord}
              onChange={(e) => setSearchWord(e.target.value)}
            />
          </div>
        </div>
        <BreadCrumbs pages={breadCrumbsPages} />
        <div className={styles.filters}>
          <div className={styles.filterBtn} onClick={() => clearFilters()}>
            Все
          </div>
          <div
            className={buttonRussianClasses}
            onClick={() => setRussian(!russian)}
          >
            Русские
          </div>
          <div
            className={buttonForeignClasses}
            onClick={() => setRussian(false)}
          >
            Зарубежные
          </div>
          <div className={styles.filterBtn} onClick={() => setGenre("Хип-хоп")}>
            Хип-хоп
          </div>
          <div
            className={buttonCorporateClasses}
            onClick={() => setCorporate(!corporate)}
          >
            Артисты на мероприятие
          </div>
        </div>
        <div className={styles.tags}>
          {currentGenres?.map(({ id, attributes }) => (
            <div
              className={classnames(
                styles.tagBtn,
                genre === attributes.title && styles.tagBtnActive
              )}
              key={id}
              onClick={() => setGenre(attributes.title)}
            >
              {attributes.title}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.cards}>
        {data?.artists?.data?.map(({ id, attributes }) => (
          <Link className={styles.card} to={`/artist/${id}`} key={id}>
            <img className={styles.cardImg} src={attributes.photoUrl} alt="" />
            <div className={styles.cardText}>{attributes.name}</div>
          </Link>
        ))}
      </div>
      {pageCount && (
        <div className={styles.paginationWrapper}>
          <ReactPaginate
            className="paginationReact"
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
          />
        </div>
      )}
      <div className={styles.textBlockWrapper}>
        <div className={styles.textBlock1}>
          <h2 className={styles.textBlockTitle}>
            Как легко заказать артиста в Москве или любом другом городе
          </h2>
          Наша база артистов очень обширна. Каталог артистов разделен на
          категории, благодаря чему удобно ориентироваться.
        </div>
        <div className={styles.textBlock2}>
          Смотрите фотографии, слушайте музыку, читайте отзывы. Хотите что-то
          масштабное? Закажите шоу любой праздник, и Ваши гости будут в восторге
          от полученных эмоций.
        </div>
      </div>
      <div className={styles.callToActionWrapper}>
        <CallToActionForm />
      </div>
    </div>
  );
};

export const Artists = connect(mapStateToProps)(ArtistsWithoutStore);
