import Moment from "react-moment";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import { useState } from "react";

// import { IConcertSummary } from "../../../../interfaces";
import styles from "./ConcertsSummariesList.module.scss";
import { IConcertSummary } from "../../../../interfaces";

export const ConcertsSummariesList = ({
  concerts,
  children,
  title = "Past concerts",
}) => {
  const [showAllConcerts, setShowAllConcerts] = useState(false);
  if (!concerts.length) return "";

  const Item = ({ concert }: IConcertSummary) => {
    return (
      <div className={styles.item}>
        <div className={styles.date}>
          <Moment format="MMM Do YY">{concert?.attributes?.date}</Moment>
        </div>
        <div className={styles.info}>
          <div className={styles.countryAndCity}>
            {concert?.attributes?.country?.data
              ? `${concert?.attributes?.country?.data?.attributes.name}, `
              : ""}
            {concert?.attributes?.city?.data?.attributes.name || ""}
          </div>
          <div className={styles.location}>
            {concert?.attributes?.location?.data?.attributes.name}
          </div>
        </div>
        {children}
      </div>
    );
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.concertsTitle}>{title}</div>
      <div>
        {concerts.slice(0, 3).map((concert, idx) => (
          <Item concert={concert} key={idx} />
        ))}
        {showAllConcerts &&
          concerts
            .slice(3)
            .map((concert, idx) => <Item concert={concert} key={idx} />)}
      </div>
      {!showAllConcerts && concerts.length >= 3 && (
        <div onClick={() => setShowAllConcerts(true)} className={styles.more}>
          More
          <ReactSVG className={styles.arrowIcon} src="/ic-arrow.svg" />
        </div>
      )}
    </div>
  );
};

ConcertsSummariesList.propTypes = {
  children: PropTypes.any,
  concerts: PropTypes.any,
  title: PropTypes.string,
};

export default ConcertsSummariesList;
