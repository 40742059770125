import React from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";

const GET_ARTICLE = gql`
  query GetArticles($url: String) {
    articles(filters: { friendlyUrl: { eq: $url } }) {
      data {
        attributes {
          title
          description
          content
          mainPictureUrl
        }
      }
    }
  }
`;

export const Article: () => React.ReactElement = () => {
  const { url } = useParams();
  const { data } = useQuery(GET_ARTICLE, {
    fetchPolicy: "network-only",
    variables: {
      url,
    },
  });
  const article = data?.articles?.data[0]?.attributes;
  if (!article) {
    return <div>Article not found</div>;
  }
  return (
    <div>
      <h1>{article.title}</h1>
      <ReactMarkdown escapeHtml={false}>{article.content}</ReactMarkdown>
    </div>
  );
};
