import classnames from "classnames";
import Input from "react-phone-number-input/input";

import styles from "./InputPhone.module.scss";

interface IProps {
  value: number;
  warning: boolean;
  wrongNumber: boolean;
  justWarning: boolean;
  onChange: (v: string) => {};
}

export const InputPhone = (props: IProps) => {
  return (
    <div
      className={classnames(
        styles.wrapper,
        props.warning && styles.wrapperWithMsg,
        props.wrongNumber && styles.wrapperWithMsgWrongNumber
      )}
    >
      <Input
        maxLength={16}
        className={classnames(
          styles.input,
          props.warning && styles.warning,
          props.justWarning && styles.onlyBorder
        )}
        required
        value={props.value}
        onChange={props.onChange}
        placeholder="Your phone number"
      />
    </div>
  );
};

export default InputPhone;
