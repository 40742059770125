import Accordion from "react-bootstrap/Accordion";
import "../../static/styles/Questions.scss";
import { GetLeadPopup } from "../common/components/index.tsx";
import { useQuery, gql } from "@apollo/client";

const GET_QUESTIONS = gql`
  query {
    questions {
      data {
        attributes {
          question
          answer
        }
      }
    }
  }
`;

export const Questions = () => {
  const { data: dataQuestion } = useQuery(GET_QUESTIONS, {
    fetchPolicy: "network-only",
  });
  return (
    <div className="questions">
      <h2 className="sub-title">Вопрос-ответ</h2>
      <p className="before-title">Часто задаваемые вопросы</p>
      <Accordion defaultActiveKey="0">
        {dataQuestion?.questions?.data.map((item, index) => (
          <Accordion.Item eventKey={index} key={index}>
            <Accordion.Header className="question">
              {item.attributes.question}
            </Accordion.Header>
            <Accordion.Body>{item.attributes.answer}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      <div className="d-flex question-footer">
        <p className="questions-text">Остались вопросы?</p>
        <GetLeadPopup />
      </div>
    </div>
  );
};
