import { Link } from "react-router-dom";

import styles from "./Footer.module.scss";

interface ILink {
  title: string;
  url: string;
}

const links: ILink[] = [
  {
    title: "Events",
    url: "/concerts/",
  },
  {
    title: "About us",
    url: "/about/",
  },
  {
    title: "Contacts",
    url: "/contacts/",
  },
];

export const Footer = () => {
  return (
    <footer className={styles.wrapper}>
      <div className={[styles.footer, "custom-container"].join(" ")}>
        <div className={styles.links}>
          {links.map((link: ILink, idx: number) => (
            <Link className={styles.link} key={idx} to={link.url}>
              {link.title}
            </Link>
          ))}
        </div>
        <div className={styles.rights}>©Atom 2022. All rights reserved.</div>
        <Link className={styles.policyLink} to="/article/privacy-policy">
          Privacy policy
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
