import { Link } from "react-router-dom";

import { slide as Menu } from "react-burger-menu";
import Accordion from "react-bootstrap/Accordion";
import "./Nav.scss";
import styles from "./Nav.module.scss";
import { GetLeadPopup } from "../index.tsx";

interface IPropsSideBar {
  handleChange: (isOpen: boolean) => void;
}

export const SideBar = (props: IPropsSideBar) => {
  return (
    <Menu {...props} className="menu">
      <div className="menu__box d-flex">
        <div className="menu-cont nav">
          <li>
            <Link
              className="menu__item high"
              to="/concerts"
              onClick={() => props.handleChange(false)}
            >
              Events
            </Link>
          </li>
          <li>
            <Link
              className="menu__item high"
              to="/artists"
              onClick={() => props.handleChange(false)}
            >
              Artists
            </Link>
          </li>
          <li>
            <Link
              onClick={() => props.handleChange(false)}
              className="menu__item high"
              to="/questions"
            >
              F.A.Q.
            </Link>
          </li>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Services</Accordion.Header>
              <Accordion.Body>
                <li>
                  <Link
                    onClick={() => props.handleChange(false)}
                    to="/activity/kd"
                    className={styles.navLink}
                  >
                    Concert activities
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => props.handleChange(false)}
                    to="/activity/services"
                    className={styles.navLink}
                  >
                    Organization of events
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => props.handleChange(false)}
                    to="/activity/events"
                    className={styles.navLink}
                  >
                    Events
                  </Link>
                </li>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="menu-bottom">
          <Link
            onClick={() => props.handleChange(false)}
            className="menu__item"
            to="/about"
          >
            About us
          </Link>
          <Link
            onClick={() => props.handleChange(false)}
            className="menu__item"
            to="/contacts"
          >
            Contacts
          </Link>
          <GetLeadPopup handleChange={props.handleChange} />
        </div>
      </div>
    </Menu>
  );
};
