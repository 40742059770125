import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";

import BreadCrumbs from "../common/components/BreadCrumbs/BreadCrumbs.tsx";
import { CallToActionForm } from "../common/components/CallToActionForm/CallToActionForm.tsx";
import { IPage, ITile } from "../../interfaces";
import TileLink from "../common/components/TileLink/TileLink.tsx";
import styles from "../../static/styles/About.module.scss";

const GET_SERVICETILES = gql`
  query {
    serviceTiles {
      data {
        attributes {
          title
          photoUrl
          description
          link
        }
      }
    }
  }
`;

const tiles: ITile[] = [
  {
    title: "To make an order",
    link: "/activity/services/",
    svg: "/tile-icon-service.svg",
  },
  {
    title: "To book an artist",
    link: "/artists/",
    svg: "/tile-icon-music.svg",
  },
  {
    title: "Event poster",
    link: "/",
    svg: "/tile-icon-calendar.svg",
  },
];

export const About = () => {
  const { data: dataServiceTiles } = useQuery(GET_SERVICETILES, {
    fetchPolicy: "network-only",
  });

  const breadCrumbsPages: IPage[] = [
    {
      link: "/",
      title: "Main",
    },
    {
      link: "/about",
      title: "About us",
    },
  ];

  return (
    <div>
      <h2 className="sub-title">ATOM Entertainment Group</h2>
      <BreadCrumbs pages={breadCrumbsPages} />
      <div className={styles.texts}>
        <div className={styles.text}>
          ATOM entertainment group - group of the companies that offers services
          for organizing public and corporate events, festivals, concerts, BTL
          and PR events. Despite its young age, atom entertainment group has
          already managed to declare itself.
        </div>
        <div className={styles.text2}>
          20 years of event experience.<br></br> We work all over the world!
        </div>
      </div>
      <div className={styles.cards}>
        {dataServiceTiles?.serviceTiles.data.map((tile, id) => (
          <div className={styles.card} key={id}>
            <img
              className={styles.cardImg}
              src={tile.attributes.photoUrl}
              alt=""
            />
            <div className={styles.cardBody}>
              <div className={styles.cardTitle}>{tile.attributes.title}</div>
              <p className={styles.cardDescription}>
                {tile.attributes.description}
              </p>
              <Link className={styles.cardBtn} to={tile.attributes.link}>
                Read more
              </Link>
            </div>
          </div>
        ))}
      </div>
      <CallToActionForm />
      <div className={styles.tiles}>
        {tiles.map((tile: ITile, idx: number) => (
          <TileLink tile={tile} key={idx} />
        ))}
      </div>
    </div>
  );
};
