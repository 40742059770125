import Modal from "react-modal";
import { useEffect, useState } from "react";
import countryNames from "../../../../static/data/countryNames.json";

const customStyles = {
  content: {
    maxWidth: "400px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#0C1216",
  },
};

Modal.setAppElement("#root");

export function RegionDetect({
  regions,
  handleChangeRegion,
  handleChangeCities,
}: string[]) {
  const [isOpen, setOpen] = useState(false);
  const [useRegion, setRegion] = useState("");

  const closeModal: () => void = () => {
    const defaultRegion = "All countries";
    handleChangeRegion(defaultRegion);
    localStorage.setItem("region", defaultRegion);
    setOpen(false);
  };

  const approveCurrentRegion = (region: string) => {
    localStorage.setItem("region", region);
    handleChangeRegion(region);
    setOpen(false);
  };

  useEffect(() => {
    const getGeo: string = async () => {
      const region = localStorage.getItem("region");
      if (!region) {
        const requestToIpInfo = await fetch(
          "https://ipinfo.io/json?token=4f0bd2c4af9aaa"
        );
        const jsonResponseIpInfo = await requestToIpInfo.json();
        if (jsonResponseIpInfo?.country) {
          const fullCountryName: string =
            countryNames[jsonResponseIpInfo.country];
          if (fullCountryName) {
            setOpen(true);
            setRegion(fullCountryName);
          }
        }
      } else {
        handleChangeRegion(region);
      }
    };
    getGeo();
  }, []);

  useEffect(() => {
    const getRegions = async () => {
      const regionsData = await fetch(
        "https://raw.githubusercontent.com/russ666/all-countries-and-cities-json/master/countries.min.json"
      );
      const regionsResponse = await regionsData.json();
      handleChangeCities(regionsResponse);
    };
    getRegions();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Region is detected"
      style={customStyles}
      id="region-detect"
    >
      <h2 style={{ fontSize: "36px" }}>Region is detected</h2>
      <div style={{ marginTop: "30px", fontSize: "18px" }}>
        We can show you events in your country
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "30px",
          maxWidth: "100%",
        }}
      >
        <button
          onClick={() => approveCurrentRegion(useRegion)}
          className="change_region send"
        >
          {useRegion}
        </button>
        <button onClick={closeModal} className="change_region send">
          All countries
        </button>
      </div>
    </Modal>
  );
}
