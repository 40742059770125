import { Link } from "react-router-dom";
import Select, { components, DropdownIndicatorProps } from "react-select";
import { connect } from "react-redux";

import countryNames from "../../../../static/data/countryNames.json";

import styles from "./Nav.module.scss";
import { ReactSVG } from "react-svg";

const mapStateToProps = (state) => {
  return {
    regions: state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    handleChangeRegion: (value) => {
      dispatch({ type: "REGIONS/SET", payload: value });
      const getRegions = async () => {
        const regionsData = await fetch(
          "https://raw.githubusercontent.com/russ666/all-countries-and-cities-json/master/countries.min.json"
        );
        const regionsResponse = await regionsData.json();
        dispatch({ type: "REGIONS/SET_CITIES", payload: regionsResponse });
      };
      getRegions();
    },
    handleChangeCities: (value) =>
      dispatch({ type: "REGIONS/SET_CITIES", payload: value }),
  };
};

interface INavProps {
  handleChange: () => void;
  handleChangeRegion: (value: string) => void;
  regions: {
    region: string;
  };
}

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    minWidth: state.selectProps.minWidth,
    backgroundColor: "#121C25",
  }),
  control: (provided, state) => ({
    ...provided,
    minWidth: state.selectProps.minWidth,
    backgroundColor: "#121C25",
    borderRadius: "22px",
    fontSize: "14px",
    paddingLeft: "20px",
    caretColor: "#3297C2",
    border: "none",
    color: "#F2F2F2",
    "&:hover": {
      cursor: "pointer",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? "#020C14" : "#121C25",
    "&:hover": {
      backgroundColor: "#3297C2",
      cursor: "pointer",
    },
  }),
  input: (provided) => ({
    ...provided,
    color: "#F2F2F2",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#F2F2F2",
    fontSize: "16px",
    textAlign: "center",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: () => ({
    marginRight: "5px",
  }),
};

interface IOption {
  value: string;
  label: string;
}

function NavWithoutStore(props: INavProps) {
  const preparedCountryNames: IOption[] = Object.values(countryNames).map(
    (i: string) => {
      return {
        value: i,
        label: i,
      };
    }
  );
  preparedCountryNames.unshift({
    value: "All countries",
    label: "All countries",
  });

  const DropdownIndicator = (props: DropdownIndicatorProps) => {
    return (
      <components.DropdownIndicator {...props}>
        <ReactSVG src="/ic-arrow.svg" />
      </components.DropdownIndicator>
    );
  };

  return (
    <header className={styles.wrapper}>
      <div className={styles.burgerWrapper}>
        <div onClick={props.handleChange} className={styles.burger}>
          <span className={styles.burgerLine}></span>
          <span className={styles.burgerLine}></span>
          <span className={styles.burgerLine}></span>
        </div>
      </div>
      <div className={styles.logoWrapper}>
        <Link to="/" alt="to the main page">
          <img className={styles.logo} src="/nav-logo.png" alt="atom" />
        </Link>
      </div>
      <div className={styles.socials}>
        <a
          className="fa-links-in-nav"
          href="https://vk.com"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa fa-vk"></i>
        </a>
        <a
          className="fa-links-in-nav"
          href="https://facebook.com"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa fa-facebook"></i>
        </a>
        <a
          className="fa-links-in-nav"
          href="https://instagram.com"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa fa-instagram"></i>
        </a>
      </div>
      <div className={styles.menuLinks}>
        <Link className={styles.menuLink} to="/concerts">
          Events
        </Link>
        <Link className={styles.menuLink} to="/about">
          About us
        </Link>
        <Link className={styles.menuLink} to="/contacts">
          Contacts
        </Link>
      </div>
      <div className={styles.regionSelectWrapper}>
        <Select
          onChange={(e: IOption) => {
            localStorage.setItem("region", e.value);
            props.handleChangeRegion(e.value);
          }}
          value={preparedCountryNames.find(
            (i: IOption) => i.value === props.regions.region
          )}
          components={{ DropdownIndicator }}
          styles={customStyles}
          minWidth="160px"
          aria-label="Default"
          placeholder="Select a region"
          options={preparedCountryNames}
        ></Select>
      </div>
    </header>
  );
}

export const Nav = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavWithoutStore);
