import classnames from "classnames";

import styles from "./Input.module.scss";

interface IProps {
  placeholder: string;
  type: string;
  warning: boolean;
  onChange: (e: string) => {};
}

export const CustomInput = (props: IProps) => {
  return (
    <div
      className={classnames(
        styles.wrapper,
        props.warning && styles.wrapperWithMsg
      )}
    >
      <input
        className={classnames(styles.input, props.warning && styles.warning)}
        type={props.type}
        placeholder={props.placeholder}
        required
        onChange={(e) => props.onChange(e.target.value)}
      />
    </div>
  );
};

export default CustomInput;
