import { connect } from "react-redux";
import { RegionDetect } from "./RegionDetect.tsx";

const mapStateToProps = (state) => {
  return {
    regions: state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    handleChangeRegion: (value) =>
      dispatch({ type: "REGIONS/SET", payload: value }),
    handleChangeCities: (value) =>
      dispatch({ type: "REGIONS/SET_CITIES", payload: value }),
  };
};
export const ContainerRegions = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegionDetect);
