import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";

import styles from "../../static/styles/MainPage.module.scss";
import "../../static/styles/MainPage.scss";

export const MainPage = () => (
  <div className={styles.wrapper}>
    <img className={styles.logo} src="/nav-logo.png" alt="" />
    <Link className={styles.link} to="/concerts">
      Events
    </Link>
    <Link className={styles.link} to="/artists">
      Artists
    </Link>
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header className={styles.accordionHeader}>
          Services
        </Accordion.Header>
        <Accordion.Body className={styles.accordionBody}>
          <li>
            <Link
              className={[styles.link, styles.linkInner].join(" ")}
              to="/activity/kd"
            >
              Concert activities
            </Link>
          </li>
          <li>
            <Link
              className={[styles.link, styles.linkInner].join(" ")}
              to="/activity/services"
            >
              Organization of events
            </Link>
          </li>
          <li>
            <Link
              className={[styles.link, styles.linkInner].join(" ")}
              to="/activity/events"
            >
              Events
            </Link>
          </li>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    <Link className={styles.link} to="/questions">
      F.A.Q.
    </Link>
    <Link className={styles.link} to="/about">
      About us
    </Link>
    <Link className={styles.link} to="/contacts">
      Contacts
    </Link>
  </div>
);
