const defaultState = {
  region: "",
  cities: [],
};

export const regionReducer = function (state = defaultState, action) {
  switch (action.type) {
    case "REGIONS/SET":
      return {
        ...state,
        region: action.payload,
      };
    case "REGIONS/SET_CITIES":
      return {
        ...state,
        cities: action.payload,
      };
    default:
      return state;
  }
};
