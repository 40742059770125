import "../../static/styles/Artist.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { ReactSVG } from "react-svg";
import { useRef, useState } from "react";
import "swiper/css/navigation";
import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import Modal from "react-modal";

import styles from "../../static/styles/Artist.module.scss";

import BreadCrumbs from "../common/components/BreadCrumbs/BreadCrumbs.tsx";
import {
  TileLink,
  ConcertsSummariesList,
  CallToActionForm,
} from "../common/components/index.tsx";
import { IPage, ITile } from "../../interfaces";

const GET_ARTIST = gql`
  query GetArtist($id: ID) {
    artist(id: $id) {
      data {
        id
        attributes {
          name
          description
          title2
          description2
          titleOfOtherBlock
          workCountries {
            data {
              attributes {
                name
              }
            }
          }
          photoUrl
          slider_media {
            data {
              attributes {
                url
                previewUrl
              }
            }
          }
          genres {
            data {
              id
              attributes {
                title
              }
            }
          }
          other_artists {
            data {
              id
              attributes {
                name
                photoUrl
              }
            }
          }
          concerts {
            data {
              attributes {
                title
                date
                city {
                  data {
                    attributes {
                      name
                    }
                  }
                }
                country {
                  data {
                    attributes {
                      name
                    }
                  }
                }
                location {
                  data {
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

Modal.setAppElement("#root");
interface IProps {
  handleChange: (isOpen: boolean) => void;
}

export const Artist = (props: IProps) => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_ARTIST, {
    fetchPolicy: "network-only",
    variables: {
      id: id,
    },
  });

  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
    props.handleChange(false);
  }

  const swiperRef = useRef(null);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  interface IArtist {
    name: string;
    description: string;
    title2: string;
    description2: string;
    titleOfOtherBlock: string;
    workCountries: {
      data: {
        attributes: {
          name: string;
        };
      }[];
    };
    photoUrl: string;
    slider_media: {
      data: {
        attributes: {
          url: string;
          previewUrl: string;
        };
      }[];
    };
    genres: {
      data: {
        id: string;
        attributes: {
          title: string;
        };
      }[];
    };
    other_artists: {
      data: {
        id: string;
        attributes: {
          name: string;

          photoUrl: string;
        };
      }[];
    };
    concerts: {
      data: {
        id: string;
        attributes: {
          title: string;
          date: string;
          city: string;
        };
      }[];
    };
  }

  const artist: IArtist = data?.artist.data.attributes;

  const tiles: ITile[] = [
    {
      title: "To make an order",
      link: "/activity/services/",
      svg: "/tile-icon-service.svg",
    },
    {
      title: "Event poster",
      link: "/",
      svg: "/tile-icon-calendar.svg",
    },
  ];

  const breadCrumbsPages: IPage[] = [
    {
      link: "/",
      title: "Main",
    },
    {
      link: "/artists",
      title: "To book an artist",
    },
    {
      link: "#",
      title: artist?.name,
    },
  ];

  return (
    <div>
      <div className="wrapper head">
        <h2 className="sub-title artist-left">{artist?.name}</h2>
      </div>
      <div className="wrapper head">
        <BreadCrumbs pages={breadCrumbsPages} />
      </div>
      <div className={styles.jumbotron}>
        <img className={styles.jumbotronImg} src={artist?.photoUrl} alt="" />
        <div className={styles.jumbotronContent}>
          <div className={styles.jumbotronTitle}>{artist?.name}</div>
          <div className={styles.jumbotronDescription}>
            {artist?.description}
          </div>
          <div className={styles.jumbotronPlace}>
            <div className={styles.geoIcon}>
              <ReactSVG src="/geoIconConcert.svg" />
            </div>
            {artist?.workCountries?.data?.map((country, idx: number) => (
              <span key={idx} className={styles.countryName}>
                {country.attributes.name}
                {artist.workCountries.data.length - 1 !== idx && ","}
              </span>
            ))}
          </div>
          <div className={styles.jumbotronButtons}>
            <div className={styles.jumbotronBtn} onClick={toggleModal}>
              Оставить заявку
            </div>
            <div className={styles.jumbotronBtn2} onClick={toggleModal}>
              Задать вопрос
            </div>
          </div>
        </div>
      </div>
      {artist?.slider_media.data.length > 0 && (
        <div className="slider">
          <Swiper
            ref={swiperRef}
            slidesPerView={1}
            breakpoints={{
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            {artist?.slider_media.data.map((item) => (
              <SwiperSlide key={item.id}>
                {item.attributes.url.toUpperCase().endsWith(".MP4") ? (
                  <video className={styles.sliderImg} controls>
                    <source src={item.attributes.url} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    className={styles.sliderImg}
                    src={item.attributes.url}
                    alt=""
                  />
                )}
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="swiper-navigation">
            <ReactSVG
              id="previousButton"
              className={styles.prevBtn}
              onClick={() => swiperRef.current.swiper.slidePrev()}
              src="/Swiper-left.svg"
            />
            <ReactSVG
              id="nextButton"
              className={styles.nextBtn}
              onClick={() => swiperRef.current.swiper.slideNext()}
              src="/Swiper-right.svg"
            />
          </div>
        </div>
      )}
      <div className={styles.textBlock}>
        <div className={styles.textBlockTitle}>
          <h5>{artist?.title2}</h5>
        </div>
        <div className={styles.textBlockDescription}>
          <p>{artist?.description2}</p>
        </div>
      </div>
      <CallToActionForm />
      <div className={styles.wrapperConcertsSummaries}>
        <ConcertsSummariesList
          concerts={artist?.concerts?.data}
          title="Future concerts"
        >
          <div className={styles.photoreportBtn}>Buy a ticket</div>
        </ConcertsSummariesList>
      </div>
      {artist?.other_artists.data.length > 0 && (
        <div className="">
          <p className={styles.otherArtistTitle}>Другие артисты жанра</p>
          <div className={styles.otherArtistsBody}>
            {artist?.other_artists?.data?.slice(0, 4).map((artist) => (
              <Link
                to={`/artist/${artist.id}`}
                className={styles.otherArtistItem}
                key={artist.id}
              >
                <div className="">
                  <img
                    src={artist.attributes.photoUrl}
                    className={styles.otherArtistImg}
                    alt=""
                  />
                  <div className={styles.otherArtistName}>
                    {artist.attributes.name}
                  </div>
                </div>
              </Link>
            ))}
            <Link
              to={`/artists/${artist?.genres?.data[0]?.attributes.title}`}
              className={styles.otherArtistsBtn}
            >
              Перейти в раздел жанра {artist?.genres?.data[0]?.attributes.title}
            </Link>
          </div>
        </div>
      )}
      <div className={styles.tiles}>
        {tiles.map((tile: ITile, idx: number) => (
          <TileLink tile={tile} key={idx} />
        ))}
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
      >
        <div className="modal-content">
          <CallToActionForm />
        </div>
      </Modal>
    </div>
  );
};
