import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";

import BreadCrumbs from "../common/components/BreadCrumbs/BreadCrumbs.tsx";
import { IPage, ITile } from "../../interfaces";
import { CallToActionForm } from "../common/components/CallToActionForm/CallToActionForm.tsx";
import TileLink from "../common/components/TileLink/TileLink.tsx";

import styles from "../../static/styles/Activity.module.scss";

const GET_ARTICLES = gql`
  query GetArticles($slug: String) {
    articles(filters: { article_tags: { slug: { contains: $slug } } }) {
      data {
        attributes {
          title
          description
          mainPictureUrl
          friendlyUrl
        }
      }
    }
  }
`;

const GET_NAME = gql`
  query GetName($slug: String) {
    articleTags(filters: { slug: { contains: $slug } }) {
      data {
        attributes {
          name
        }
      }
    }
  }
`;

const tiles: ITile[] = [
  {
    title: "To book an artist",
    link: "/artists/",
    svg: "/tile-icon-music.svg",
  },
  {
    title: "Event poster",
    link: "/",
    svg: "/tile-icon-calendar.svg",
  },
];

export const Activity = () => {
  const { tag } = useParams();
  const { data } = useQuery(GET_ARTICLES, {
    fetchPolicy: "network-only",
    variables: {
      slug: tag,
    },
  });
  const { data: dataTag } = useQuery(GET_NAME, {
    fetchPolicy: "network-only",
    variables: {
      slug: tag,
    },
  });

  const article = dataTag?.articleTags?.data[0]?.attributes;

  const breadCrumbsPages: IPage[] = [
    {
      link: "/",
      title: "Main",
    },
    {
      link: "#",
      title: "Activities",
    },
    {
      link: "#",
      title: article?.name,
    },
  ];

  return (
    <div className="concert">
      <h2 className="sub-title">{article?.name}</h2>
      <BreadCrumbs pages={breadCrumbsPages} />
      <div className={styles.cards}>
        {data?.articles.data.map(({ attributes }, id) => (
          <div className={styles.card} key={id}>
            <img
              className={styles.cardImg}
              src={attributes.mainPictureUrl}
              alt=""
            />
            <div className={styles.cardBody}>
              <Link to={`/article/${attributes.friendlyUrl}`}>
                <div className={styles.cardTitle}>{attributes.title}</div>
                {attributes.description && (
                  <div className={styles.cardDescription}>
                    {attributes.description}
                  </div>
                )}
              </Link>
            </div>
          </div>
        ))}
      </div>
      <CallToActionForm />
      <div className={styles.tiles}>
        {tiles.map((tile: ITile, idx: number) => (
          <TileLink tile={tile} key={idx} />
        ))}
      </div>
    </div>
  );
};
