import { useState } from "react";
import "./GetLeadPopup.scss";
import Modal from "react-modal";
import { CallToActionForm } from "../index.tsx";

Modal.setAppElement("#root");

interface IPropsGetLeadPopup {
  handleChange: (isOpen: boolean) => void;
}

export function GetLeadPopup(props: IPropsGetLeadPopup) {
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
    props.handleChange(false);
  }

  return (
    <div className="Modal">
      <button className="contact" onClick={toggleModal}>
        Contact us
      </button>

      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
      >
        <div className="modal-content">
          <CallToActionForm />
        </div>
      </Modal>
    </div>
  );
}
