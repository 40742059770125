import * as React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";

import { store } from "./configure-store.tsx";

import "./index.css";
import App from "./App.tsx";

const cache = new InMemoryCache({
  typePolicies: {
    queryType: true,
    fields: {
      Concert: {
        merge: true,
      },
    },
  },
});

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  cache: cache,
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>
);
